
import CountrySelector from "./CountrySelector.vue";

export default {
  components: {
    CountrySelector,
  },
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: "",
        phone: "+971 ",
        contactMethod: "phone",
      },
      dialCode: "+971", // Default dial code
      formSubmitted: false, // State to manage form submission
    };
  },
  computed: {
    isArabic() {
      return this.$i18n.locale === "ar";
    },
  },
  methods: {
    closeForm() {
      this.$emit("close"); // Emit event to close the form
    },
    async submitForm() {
      try {
        const response = await this.$axios.post("/api/contact", this.formData);
        this.formSubmitted = true; // Update the state to show the thank you message
      } catch (error) {
        console.error("There was an error submitting the form:", error);
      }
    },
    updatePhoneCode({ dialCode }) {
      this.dialCode = dialCode;
      this.formData.phone = dialCode + " ";
    },
    updatePhoneNumber(phoneNumber) {
      this.formData.phone = phoneNumber;
    },
  },
};
