
export default {
  data() {
    return {
      newLocation: [],
      selectedLocation: "",
      pickupDateTime: "",
      dropoffDateTime: "",
    };
  },
  methods: {
    async filterCars() {
      try {
        if (
          !this.selectedLocation ||
          !this.dropoffDateTime ||
          !this.pickupDateTime
        ) {
          alert("Please select all fields");
          return;
        }

        const query = {
          location: this.selectedLocation,
          pickupDateTime: this.pickupDateTime,
          dropoffDateTime: this.dropoffDateTime,
        };

        const queryString = Object.keys(query)
          .map((key) => `${key}=${encodeURIComponent(query[key])}`)
          .join("&");

        const newUrl = `/cars?${queryString}`;
        // Store pickupDate, dropoffDate, pickupTime, and dropoffTime in local storage
        // localStorage.setItem("pickupDateTime", this.pickupDateTime);
        // localStorage.setItem("dropoffDateTime", this.dropoffDateTime);
        // localStorage.setItem("selectedLocation", this.selectedLocation);

        // Use push instead of replace to see if it makes a difference
        await this.$router.replace(this.localePath(newUrl));
        return false;
      } catch (error) {
        console.error("Error filtering cars:", error);
      }
    },
  },
  async created() {
    /* locations list */
    try {
      const response = await this.$axios.get("/api/locations");

      if (!response.status) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = response.data;
      if (responseData.status === true && responseData.data) {
        this.newLocation = responseData.data;
      } else {
        console.error("Invalid response format:", responseData);
      }
    } catch (error) {
      console.error("Error getting locations:", error);
    }
  },
};
