import { render, staticRenderFns } from "./CountrySelector.vue?vue&type=template&id=64263b5c&scoped=true"
import script from "./CountrySelector.vue?vue&type=script&lang=js"
export * from "./CountrySelector.vue?vue&type=script&lang=js"
import style0 from "./CountrySelector.vue?vue&type=style&index=0&id=64263b5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64263b5c",
  null
  
)

export default component.exports