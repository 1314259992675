
export default {
  props: ["value", "placeholder"],
  data() {
    return {
      date: null,
      time: "00:00",
      showDateDialog: false,
      showTimeDialog: false,
      displayDateTime: "",
    };
  },
  computed: {
    currentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so +1 is needed
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}/${month}/${day}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const [datePart, timePart] = newVal.split(" ");
          this.date = datePart;
          this.time = timePart;
          this.displayDateTime = newVal;
        }
      },
    },
  },
  methods: {
    updateDateTime() {
      if (this.date && this.time) {
        this.displayDateTime = `${this.date} ${this.time}`;
        this.$emit("input", this.displayDateTime);
      }
    },
    triggerTimeModal() {
      if (!this.date) {
        return;
      }
      this.displayDateTime = `${this.date} ${this.time}`;
      this.$emit("input", this.displayDateTime);
      this.showDateDialog = false;
      this.showTimeDialog = true;
    },
  },
};
