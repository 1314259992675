
export default {
  data() {
    return {
      selectedCountry: "AE", // Default country code for UAE
      showDropdown: false,
      phoneNumber: "+971 ", // Including the default country code
      countries: [
        { name: "Algeria", code: "DZ", dial_code: "+213" },
        { name: "Bahrain", code: "BH", dial_code: "+973" },
        { name: "Comoros", code: "KM", dial_code: "+269" },
        { name: "Djibouti", code: "DJ", dial_code: "+253" },
        { name: "Egypt", code: "EG", dial_code: "+20" },
        { name: "Iraq", code: "IQ", dial_code: "+964" },
        { name: "Jordan", code: "JO", dial_code: "+962" },
        { name: "Kuwait", code: "KW", dial_code: "+965" },
        { name: "Lebanon", code: "LB", dial_code: "+961" },
        { name: "Libya", code: "LY", dial_code: "+218" },
        { name: "Mauritania", code: "MR", dial_code: "+222" },
        { name: "Morocco", code: "MA", dial_code: "+212" },
        { name: "Oman", code: "OM", dial_code: "+968" },
        { name: "Palestine, State of", code: "PS", dial_code: "+970" },
        { name: "Qatar", code: "QA", dial_code: "+974" },
        { name: "Saudi Arabia", code: "SA", dial_code: "+966" },
        { name: "Somalia", code: "SO", dial_code: "+252" },
        { name: "Sudan", code: "SD", dial_code: "+249" },
        { name: "Syrian Arab Republic", code: "SY", dial_code: "+963" },
        { name: "Tunisia", code: "TN", dial_code: "+216" },
        { name: "United Arab Emirates", code: "AE", dial_code: "+971" },
        { name: "Yemen", code: "YE", dial_code: "+967" },
        { name: "Albania", code: "AL", dial_code: "+355" },
        { name: "Andorra", code: "AD", dial_code: "+376" },
        { name: "Armenia", code: "AM", dial_code: "+374" },
        { name: "Austria", code: "AT", dial_code: "+43" },
        { name: "Azerbaijan", code: "AZ", dial_code: "+994" },
        { name: "Belarus", code: "BY", dial_code: "+375" },
        { name: "Belgium", code: "BE", dial_code: "+32" },
        { name: "Bosnia and Herzegovina", code: "BA", dial_code: "+387" },
        { name: "Bulgaria", code: "BG", dial_code: "+359" },
        { name: "Croatia", code: "HR", dial_code: "+385" },
        { name: "Cyprus", code: "CY", dial_code: "+357" },
        { name: "Czech Republic", code: "CZ", dial_code: "+420" },
        { name: "Denmark", code: "DK", dial_code: "+45" },
        { name: "Estonia", code: "EE", dial_code: "+372" },
        { name: "Finland", code: "FI", dial_code: "+358" },
        { name: "France", code: "FR", dial_code: "+33" },
        { name: "Georgia", code: "GE", dial_code: "+995" },
        { name: "Germany", code: "DE", dial_code: "+49" },
        { name: "Greece", code: "GR", dial_code: "+30" },
        { name: "Hungary", code: "HU", dial_code: "+36" },
        { name: "Iceland", code: "IS", dial_code: "+354" },
        { name: "Ireland", code: "IE", dial_code: "+353" },
        { name: "Italy", code: "IT", dial_code: "+39" },
        { name: "Kazakhstan", code: "KZ", dial_code: "+7" },
        { name: "Latvia", code: "LV", dial_code: "+371" },
        { name: "Lithuania", code: "LT", dial_code: "+370" },
        { name: "Luxembourg", code: "LU", dial_code: "+352" },
        { name: "Malta", code: "MT", dial_code: "+356" },
        { name: "Moldova", code: "MD", dial_code: "+373" },
        { name: "Monaco", code: "MC", dial_code: "+377" },
        { name: "Montenegro", code: "ME", dial_code: "+382" },
        { name: "Netherlands", code: "NL", dial_code: "+31" },
        { name: "North Macedonia", code: "MK", dial_code: "+389" },
        { name: "Norway", code: "NO", dial_code: "+47" },
        { name: "Poland", code: "PL", dial_code: "+48" },
        { name: "Portugal", code: "PT", dial_code: "+351" },
        { name: "Romania", code: "RO", dial_code: "+40" },
        { name: "Russian Federation", code: "RU", dial_code: "+7" },
        { name: "San Marino", code: "SM", dial_code: "+378" },
        { name: "Serbia", code: "RS", dial_code: "+381" },
        { name: "Slovakia", code: "SK", dial_code: "+421" },
        { name: "Slovenia", code: "SI", dial_code: "+386" },
        { name: "Spain", code: "ES", dial_code: "+34" },
        { name: "Sweden", code: "SE", dial_code: "+46" },
        { name: "Switzerland", code: "CH", dial_code: "+41" },
        { name: "Turkey", code: "TR", dial_code: "+90" },
        { name: "Ukraine", code: "UA", dial_code: "+380" },
        { name: "United Kingdom", code: "GB", dial_code: "+44" },
        { name: "Argentina", code: "AR", dial_code: "+54" },
        { name: "Bolivia", code: "BO", dial_code: "+591" },
        { name: "Brazil", code: "BR", dial_code: "+55" },
        { name: "Chile", code: "CL", dial_code: "+56" },
        { name: "Colombia", code: "CO", dial_code: "+57" },
        { name: "Ecuador", code: "EC", dial_code: "+593" },
        { name: "Guyana", code: "GY", dial_code: "+592" },
        { name: "Paraguay", code: "PY", dial_code: "+595" },
        { name: "Peru", code: "PE", dial_code: "+51" },
        { name: "Suriname", code: "SR", dial_code: "+597" },
        { name: "Uruguay", code: "UY", dial_code: "+598" },
        { name: "Venezuela", code: "VE", dial_code: "+58" },
        { name: "Antigua and Barbuda", code: "AG", dial_code: "+1-268" },
        { name: "Bahamas", code: "BS", dial_code: "+1-242" },
        { name: "Barbados", code: "BB", dial_code: "+1-246" },
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectCountry(country) {
      this.selectedCountry = country.code;
      this.$emit("country-changed", {
        code: country.code,
        dialCode: country.dial_code,
      });
      this.phoneNumber = country.dial_code + " ";
      this.showDropdown = false;
    },
    updatePhoneNumber() {
      const phoneNumberWithoutCode = this.phoneNumber.replace(
        this.countries.find((country) => country.code === this.selectedCountry)
          .dial_code,
        ""
      );
      this.$emit("phone-number-updated", this.phoneNumber);
    },
  },
};
