
import Chat from "../components/CustomChatComponent.vue";

export default {
  name: "YasminaAssistant",
  components: {
    Chat,
  },
  data() {
    return {
      showChatbot: false,
      chatbotData: null, // Initialize chatbotData as null
      // messages: [
      //   "👋مرحبًا! كيف يمكنني مساعدتك اليوم؟",
      //   "ماذا عن التامينات ؟",
      //   "استئجار سيارة بسعر رخيص",
      //   "ما هي شروط تاجير سياره من مطار دبي؟",
      //   "ماذا عن المخالفات المروريه؟",
      //   "تأجير سيارات عائلية رخيصة",
      //   "تأجير سيارات في دبي رخيصة",
      // ],
      messages: [
        {
          key: "welcomeMessage",
          // text: "👋مرحبًا! كيف يمكنني مساعدتك اليوم؟"
        },
        {
          key: "insurances",
          // text: "ماذا عن التامينات ؟"
        },
        {
          key: "RentingCheapPrice",
          // text: "استئجار سيارة بسعر رخيص"
        },
        {
          key: "ConditionsRentingAirport",
          // text: "ما هي شروط تاجير سياره من مطار دبي؟",
        },
        {
          key: "trafficViolations",
          // text: "ماذا عن المخالفات المروريه؟",
        },

        {
          key: "familyCars",
          // text: "تأجير سيارات عائلية رخيصة",
        },
        {
          key: "RentingCheapCarsInDubai",
          // text: "تأجير سيارات في دبي رخيصة",
        },
      ],
    };
  },
  async asyncData({ $content }) {
    // Fetch initial data for your page
    const pageData = await $content("fetchPageData"); // Replace with your function

    // Optionally, fetch initial data for the chatbot if needed
    const chatbotData = await $content("fetchInitialChatbotData"); // Adjust function name

    return { pageData, chatbotData };
  },
};
