
export default {
  props: {
    car: Object,
  },
  methods: {
    redirectToWhatsApp() {
      const whatsappLink =
        "https://api.whatsapp.com/send?phone=971504608424&text=";
      window.open(whatsappLink, "_blank");
    },
    getStarArray() {
      const starCount = Math.ceil(this.car.stars);
      return starCount > 0
        ? Array(starCount)
            .fill(0)
            .map((_, index) => index + 1)
        : [];
    },
  },
};
