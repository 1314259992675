
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import Yasmina from "../components/YasminaAssistant.vue";

export default {
  components: {
    vSelect,
    Hooper,
    Slide,
    Yasmina,
    PopupForm: () => import("../components/PopupForm.vue"),
  },
  data() {
    return {
      selectedCarType: "all", // Default selected car type
      selectedPriceFilter: "",
      isLoading: false,
      showPopup: false,

      carouselItems: [
        {
          name: "عبد الله الاسمري",
          role: "Date- 09/10/2023",
          description:
            "الله يعطيك العافيه وباكون عميل لكم باذن الله ... شكرا على المعامله الجميله",
          imageSrc: "/customer/Abdullah Ali S.webp",

          altText: "Customer feedback graphic",
          icons: [
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
          ],
        },
        {
          name: "محمد وليد الشمري",
          role: "Date- 14/07/2023",
          description:
            "مشكور على الخدمه الممتازه. جزاك الله خير، سررت بلقائكم وان شاء الله نجدد معاكم مره اخرى.",
          imageSrc: "/customer/mohame weled.webp",

          altText: "Customer feedback graphic",
          icons: [
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
          ],
        },
        {
          name: "فريد ادم ",
          role: "Date- 09/04/2023",
          description:
            "تمنياتي لكم بالخير, شكرا لكم والى لقاء قريب مره اخرى ان شاء الله اقدر جدا مجهودكم للمساعده",
          imageSrc: "/customer/freddy Adam.webp",

          altText: "Customer feedback graphic",
          icons: [
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 96 960 960",
              fill: "currentColor",
              d: "m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z",
            },
          ],
        },

        // Add more carousel items as needed
      ],

      hooperSettings: {
        itemsToShow: 1,
        itemsToSlide: 1,
      },
    };
  },
  computed: {
    filteredCars() {
      this.seeAll = false;
      // Filter the cars based on both selected car type and price
      let filteredByType =
        this.selectedCarType === "all"
          ? this.$store.state.carsList
          : this.$store.state.carsList.filter(
              (car) => car.car_type === this.selectedCarType
            );

      if (this.selectedPriceFilter === "high") {
        return filteredByType.sort((a, b) => b.price_per_day - a.price_per_day);
      } else if (this.selectedPriceFilter === "low") {
        return filteredByType.sort((a, b) => a.price_per_day - b.price_per_day);
      } else {
        return filteredByType;
      }
    },
  },

  methods: {
    openForm() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },

    slidePrev() {
      this.$refs.carouselPress.slidePrev();
    },

    slideNext() {
      this.$refs.carouselPress.slideNext();
    },
    handleArrowClick() {
      // Get a reference to the target section
      const carsSection = this.$refs.carsSection;

      // Check if the section reference exists
      if (carsSection) {
        // Scroll to the target section
        carsSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleTypeChange(carType) {
      this.selectedCarType = carType;
    },

    // seeAllClick() {
    //   this.selectedCarType = null;
    //   this.$nextTick(() => {
    //     this.seeAll = true;
    //   });
    // },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("fetchCarsList");
    } catch (error) {
      console.error("Error fetching cars list:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
