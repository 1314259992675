import { render, staticRenderFns } from "./PopupForm.vue?vue&type=template&id=09978e72&scoped=true"
import script from "./PopupForm.vue?vue&type=script&lang=js"
export * from "./PopupForm.vue?vue&type=script&lang=js"
import style0 from "./PopupForm.vue?vue&type=style&index=0&id=09978e72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09978e72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CountrySelector: require('/home/oday/web/alwifaqrentacar.com/public_html/components/CountrySelector.vue').default})
